import * as React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { CustomDataProvider } from '../provider/CustumDataProvider';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  DateField,
  SelectInput,
  DateInput,
  useInfiniteGetList
} from "react-admin";
import ReactToPrint from "react-to-print";
import ComponentToPrint, { CsvFile } from "./ComponentToPrint";
import Empty from "../empty/Empty";
import { Button , Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

const { roles } = JSON.parse(localStorage.getItem("auth")) || { roles: [] };

const OrderBulkActionButtons = (props) => {
  const [exportData, setExportData] = useState([]);
  const componentRef = useRef();
  const initialExportData = useMemo(() => [], []);

  const handleClick = () => {
    if (props.selectedIds.length > 0) {
      CustomDataProvider.getMany("api/orders", {
        ids: [props.selectedIds],
      }).then(function (response) {
        setExportData(response?.data);
      });
    }
  };

  useEffect(() => {
    handleClick();
  }, [props.selectedIds]);

  return (
    <React.Fragment>
      <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ComponentToPrint ref={componentRef} printData={exportData} />
        <ReactToPrint
          trigger={() => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "20px" }}>
              <ArrowDownwardIcon />
              <Button
                style={{ cursor: "pointer", border: "none", padding: "5px 10px", borderRadius: "5px" }}
                onClick={() => {
                  setExportData(initialExportData);
                  handleClick();
                }}
              >
                {exportData?.length === 0 ? "Download" : "Download"}
              </Button>
            </div>
          )}
          content={() => componentRef.current}
        />
        <CsvFile orders={exportData} />
      </div>
    </React.Fragment>
  );
};

export const OrderList = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [debouncedStatus, setDebouncedStatus] = useState("");
  const [debouncedWarehouse, setDebouncedWarehouse] = useState("");
  const [debouncedStartDate, setDebouncedStartDate] = useState("");
  const [debouncedEndDate, setDebouncedEndDate] = useState("");

  const [filtersApplied, setFiltersApplied] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const handleRowClick = (id) => {
    const editUrl = `/admin/api/orders/${id}`;
    window.open(editUrl, '_blank');
  };

  const applyAllFilters = () => {
    console.log("Applying filters:", { status, warehouse, startDate, endDate, searchText });
    setDebouncedStatus(status);
    setDebouncedWarehouse(warehouse);
    setDebouncedStartDate(startDate);
    setDebouncedEndDate(endDate);
    setDebouncedSearchText(searchText);
    setFiltersApplied(true);
  };

  const clearAllFilters = () => {
    setDebouncedSearchText('');
    setDebouncedStatus('');
    setDebouncedWarehouse('');
    setDebouncedStartDate('');
    setDebouncedEndDate('');
    setSearchText('');
    setStatus('');
    setWarehouse('');
    setEndDate('');
    setStartDate('');
    navigate("/admin/api/orders?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=10&sort=id");
  };

  const validateDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return false;
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays <= 31;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };

  const showAlert = (message) => {
    setDialogMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const exportToCsv = () => {
    if (!debouncedStartDate || !debouncedEndDate) {
      showAlert("Please select both start and end dates for export.");
      return;
    }

    if (!validateDateRange(debouncedStartDate, debouncedEndDate)) {
      showAlert("Date range should not exceed 31 days.");
      return;
    }

    CustomDataProvider.getList('api/orders', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
      filter: {
        q: debouncedSearchText,
        status: debouncedStatus,
        warehouse: debouncedWarehouse,
        startDate: debouncedStartDate,
        endDate: debouncedEndDate,
      }
    }).then(({ data }) => {
      const csvContent = [
        ['Order Number', 'Store (Warehouse)', 'Order Date', 'Subtotal', 'Coupon Discount', 'Shipping & Handling', 'Grand Total', 'Status', 'POS Bill Number', 'Customer Name', 'Customer Mobile', 'Tel:', 'Mail Id', 'Payment', 'Address 1', 'Address 2'].join(','),
        ...data.map(order => {
          const address2 = [
            order.shipping_address.address2,
            order.shipping_address.city,
            order.shipping_address.province,
            order.shipping_address.zip,
            order.shipping_address.country
          ].filter(Boolean).join(', ');

          return [
            order.order_number,
            order.warehouse,
            formatDate(order.processed_at),
            order.current_subtotal_price,
            order.discount,
            order.shipment,
            order.total_price,
            order.status,
            order.customer.posBillNumber,
            `${order.customer.first_name} ${order.customer.last_name}`,
            order.customer.phone || '',
            order.shipping_address.phone,
            order.customer.email,
            order.gateway,
            `"${order.shipping_address.address1}"`,
            `"${address2}"`
          ].join(',');
        })
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'orders_export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const ListActions = () => {
    const isAdmin = roles?.includes("admin");
    const isFilterApplied = filtersApplied && (debouncedSearchText || debouncedStatus || debouncedWarehouse || debouncedStartDate || debouncedEndDate);
    const isDateRangeValid = validateDateRange(debouncedStartDate, debouncedEndDate);
  
    const handleApplyFilters = () => {
      console.log("handleApplyFilters called", { startDate, endDate });
      
      if (startDate && endDate) {
        if (!validateDateRange(startDate, endDate)) {
          showAlert("Date range should not exceed 31 days.");
        } else {
          applyAllFilters();
        }
      } else if (startDate || endDate) {
        showAlert("Please select both start and end dates.");
      } else {
        applyAllFilters();
      }
    };
  
    return (
      <TopToolbar>
        {isAdmin && isFilterApplied && isDateRangeValid && (
          <Button 
            style={{ fontSize: '12px', marginRight: '10px', marginBottom: '10px' }} 
            onClick={exportToCsv}
            variant="contained"
          >
            Export  .CSV
          </Button>
        )}
        <div style={{display:'flex', gap:'10px', marginBottom:'10px'}}>
          <Button style={{fontSize:'12px'}} onClick={handleApplyFilters} variant="contained">Apply Filters</Button>
          <Button style={{fontSize:'12px'}} onClick={clearAllFilters} variant="contained">Clear Filters</Button>
        </div>
      </TopToolbar>
    );
  };

  const orderFilters = [
    <TextInput
      label="Search by shopify order number or name"
      source="q"
      alwaysOn
      style={{ width: "310px" }}
      onChange={(e) => setSearchText(e.target.value)}
      value={searchText}
    />,
    <SelectInput
      alwaysOn
      source="status"
      label="Status"
      choices={[
        { id: "where%5Bstatus%5D[equals]=pending", name: "Pending" },
        { id: "where%5Bstatus%5D[equals]=product_missing", name: "Product missing" },
        { id: "where%5Bstatus%5D[equals]=billed", name: "Billed" },
        { id: "where%5Bstatus%5D[equals]=dispatch", name: "Ready to Dispatch" },
        { id: "where%5Bstatus%5D[equals]=dispatched", name: "Dispatched" },
        { id: "where%5Bstatus%5D[equals]=delivered", name: "Delivered" },
        { id: "where%5Bstatus%5D[equals]=return", name: "Return" },
        { id: "where%5Bstatus%5D[equals]=cancelled", name: "Cancelled" },
        { id: "where%5Bstatus%5D[equals]=closed", name: "Closed" },
      ]}
      value={status}
      onChange={(e) => setStatus(e.target.value)}
    />,
    <SelectInput
      alwaysOn
      source="warehouse"
      label="Warehouse"
      choices={[
        { id: "where%5Bwarehouse%5D[equals]=Tardeo", name: "Tardeo" },
        { id: "where%5Bwarehouse%5D[equals]=Goregaon", name: "Goregaon" },
        { id: "where%5Bwarehouse%5D[equals]=Thane", name: "Thane" },
        { id: "where%5Bwarehouse%5D[equals]=NaviMumbai", name: "NaviMumbai" },
        { id: "where%5Bwarehouse%5D[equals]=Others", name: "Others" },
      ]}
      value={warehouse}
      onChange={(e) => setWarehouse(e.target.value)}
    />,
    <DateInput
      onChange={(e) => setStartDate(e.target.value)}
      source="startDate"
      alwaysOn
      label="Start date"
      value={startDate}
      style={{ width: "140px" }}
    />,
    <DateInput
      onChange={(e) => setEndDate(e.target.value)}
      source="endDate"
      alwaysOn
      label="End Date"
      value={endDate}
      style={{ width: "140px" }}
    />,
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get("q") || "";
    const status = searchParams.get("status") || "";
    const warehouse = searchParams.get("warehouse") || "";
    const startDate = searchParams.get("startDate") || "";
    const endDate = searchParams.get("endDate") || "";
    setDebouncedSearchText(q);
    setDebouncedStatus(status);
    setDebouncedWarehouse(warehouse);
    setDebouncedStartDate(startDate);
    setDebouncedEndDate(endDate);
  }, [location.search]);

  const {
    data,
    total,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetList("api/orders", {
    pagination: { page: 1, perPage: 25 },
    filter: {
      q: debouncedSearchText,
      status: debouncedStatus,
      warehouse: debouncedWarehouse,
      startDate: debouncedStartDate,
      endDate: debouncedEndDate,
    },
  });

  if (isLoading) {
    return (
      <p style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </p>
    );
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <List
        dense
        filters={orderFilters}
        actions={<ListActions />}
        exporter={false}
        pagination={false}
      >
        <Datagrid
          data={data?.pages.flatMap((page) => page.data)}
          empty={<Empty />}
          total={total}
          rowClick={(id, event) => {
            handleRowClick(id);
          }}
          bulkActionButtons={<OrderBulkActionButtons />}
        >
          <TextField source="order_number" label="Order Number" />
          <TextField source="warehouse" label="Store" />
          <TextField source="shipping_address.name" label="Ship-To-Name" />
          <DateField source="processed_at" label="Purchase date" />
          <TextField source="total_price" label="Grand Total ₹" />
          <TextField source="status" label="Status" />
          <TextField source="customer.posBillNumber" label="POS Bill Number" />
          <TextField source="customer.warehouseComment" label="Comment" />
        </Datagrid>
      </List>
      {hasNextPage && (
        <Button
          style={{ marginTop: "20px", marginBottom: "20px" }}
          variant="contained"
          disabled={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          Load More
        </Button>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};